import React, { Component } from "react";

import { Includes } from "../framework/Includes";
import CustomElement from "../framework/CustomElement";
import LayoutRowDiv from "../framework/LayoutRowDiv";
import LayoutColumnDiv from "../framework/LayoutColumnDiv";
import YoutubeSubscribe from "../framework/YoutubeSubscribe";
import FacebookLike from "../framework/FacebookLike";
import InstagramFeed from "../components/InstagramFeed";
import YoutubePlaylists from "../framework/YoutubePlaylists";
 
class Media extends Component {

	render() {
		return (

			<CustomElement Type="div" Id="MediaContainer">
				<LayoutRowDiv>
	    			<LayoutColumnDiv Columns="twelve">
	    				<CustomElement Type="h1" Class="margin-top-25 center-align">{Includes.MediaMainTitle}</CustomElement>
	    			</LayoutColumnDiv>
	    		</LayoutRowDiv>

	    		<YoutubeSubscribe />
	    		<FacebookLike />
	    		<hr />

	    		<InstagramFeed />

	    		<hr />

	    		<LayoutRowDiv Class="margin-top-25 center-align">
	    			<LayoutColumnDiv Columns="twelve" Class="margin-top-25">
	    				<CustomElement Type="h1" Id="MediaVideoTitle">{Includes.MediaSecondTitle}</CustomElement>
	    			</LayoutColumnDiv>
	    		</LayoutRowDiv>

	    		<YoutubePlaylists />

	    	</CustomElement>
		);
	}
}
 
export default Media;