import React, { Component } from "react";

import LayoutRowDiv from "../framework/LayoutRowDiv";
import LayoutColumnDiv from "../framework/LayoutColumnDiv";
 
class FacebookLike extends Component {

	render() {
		return (
			<LayoutRowDiv Class="margin-top-25 center-align">
    			<LayoutColumnDiv Columns="twelve" Class="margin-top-25">
    				<iframe title="facebook-like" src="https://www.facebook.com/plugins/like.php?href=http%3A%2F%2Ffacebook.com%2Fpaulcutlertaekwondoacademy&width=93&layout=button_count&action=like&size=large&show_faces=true&share=false&height=21&appId" width="93" height="28" className="facebook-like-plugin" scrolling="no" frameBorder="0" allow="encrypted-media"></iframe>
    			</LayoutColumnDiv>
    		</LayoutRowDiv>
		);
	}
}
 
export default FacebookLike;