import React, { Component } from 'react';

import CustomElement from './CustomElement.js';

class LayoutRowDiv extends Component {
	
	constructor() {
		
		super();

		this.state = {};
	}

	render() {

		const RowClass = "row";
		const Class = (this.props.Class === undefined) ? RowClass : RowClass + " " + this.props.Class;
		const Id = (this.props.Id === undefined) ? "" : this.props.Id;

		const Content = (this.props.children === undefined) ? "" : this.props.children;

	    return (

	        <CustomElement Type="div" Class={Class} Id={Id}>{Content}</CustomElement>
	    );
  	}
}

export default LayoutRowDiv;