import React, { Component } from "react";
import * as contentful from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { TwitterTimelineEmbed } from 'react-twitter-embed';


import { Includes } from "../framework/Includes";
import CustomElement from "../framework/CustomElement";
import LayoutRowDiv from "../framework/LayoutRowDiv";
import LayoutColumnDiv from "../framework/LayoutColumnDiv";
import LocationMap from "../framework/LocationMap";

import BlurbImage1 from "../images/CarHomepage.png";
import BlurbImage2 from "../images/KickHomepage.png";
import BlurbImage3 from "../images/FlyHomepage.png";

class Home extends Component {

	componentDidMount() {

		//	Initialize Contentful
		const ContentfulClient = contentful.createClient({

			space: "ylj8exbi749r",
			accessToken: "5244c7b607d4ac81e4ad54c45b3d1a23d246325588a73a87b97233659ec4f1a5"
		});

		ContentfulClient.getEntry("7sjQMh5QF96YW4pyjRsYXC").then(Entry => {
			
			if (Entry.fields) {

				var Collection = Entry.fields;

				Object.keys(Collection).forEach(Key => {

					if (Key !== "title") {
						
						var ParsedEntry = documentToHtmlString(Collection[Key]);
						document.getElementById(Key).innerHTML = ParsedEntry;
					}
				});
			}
		});
	}

	render() {

		return (
			<CustomElement Type="div" Id="HomeContainer">
				<LayoutRowDiv>
	    			<LayoutColumnDiv Columns="twelve">
	    				<CustomElement Type="h1" Class="margin-top-25 center-align">{Includes.HomeMainTitle}</CustomElement>
	    			</LayoutColumnDiv>
	    		</LayoutRowDiv>

	    		<LayoutRowDiv Class="margin-top-25">
	    			<LayoutColumnDiv Columns="four" Class="margin-top-25">
	    				<CustomElement Type="img" Class="image-responsive about-image" Source={BlurbImage1} />
	    				<hr />
	    				<CustomElement Type="span" Class="center-align" Id="blurb1left"></CustomElement>
	    			</LayoutColumnDiv>

	    			<LayoutColumnDiv Columns="four" Class="margin-top-25">
	    				<CustomElement Type="img" Class="image-responsive about-image" Source={BlurbImage2} />
	    				<hr />
	    				<CustomElement Type="span" Class="center-align" Id="blurb2center"></CustomElement>
	    			</LayoutColumnDiv>

	    			<LayoutColumnDiv Columns="four" Class="margin-top-25">
	    				<CustomElement Type="img" Class="image-responsive about-image" Source={BlurbImage3} />
	    				<hr />
	    				<CustomElement Type="span" Class="center-align" Id="blurb3right"></CustomElement>
	    			</LayoutColumnDiv>
	    		</LayoutRowDiv>

	    		<hr />

	    		<LayoutRowDiv Class="margin-top-25 center-align">
	    			<LayoutColumnDiv Columns="twelve" Class="margin-top-25">
	    				<CustomElement Type="h1">{Includes.HomeSecondTitle}</CustomElement>
	    			</LayoutColumnDiv>
	    		</LayoutRowDiv>

	    		<LayoutRowDiv Class="margin-top-25">
	    			<LayoutColumnDiv Columns="six">
	    				<LocationMap />
	    			</LayoutColumnDiv>

	    			<LayoutColumnDiv Columns="six" Class="twitter-holder">
	    				<TwitterTimelineEmbed sourceType="profile" screenName="PC_TaekwonDo" options={{height: 470, 'data-chrome': 'nofooter, transparent, noheader'}} />
	    			</LayoutColumnDiv>
	    		</LayoutRowDiv>
    		</CustomElement>
		);
	}
}
 
export default Home;