import React, { Component } from "react";

import CustomElement from "../framework/CustomElement";
import LayoutRowDiv from "../framework/LayoutRowDiv";
import LayoutColumnDiv from "../framework/LayoutColumnDiv";

import PayPalButtonImage from "../images/paypalpay.png";
 
class PayPalButton extends Component {

	constructor(props) {

		super(props);
		this.state = ({ ItemName: "", Amount: 0.00, BusinessName: "cutler.tkd@virgin.net", CurrencyCode: "GBP",  });
	}

	componentDidMount(props) {
		
		const ItemName = this.props.ItemName;
		const Amount = this.props.Amount;
		this.setState({ ItemName: ItemName, Amount: Amount });
	}

	componentWillReceiveProps(NewProps) {

		const ItemName = NewProps.ItemName;
		const Amount = NewProps.Amount;
		this.setState({ ItemName: ItemName, Amount: Amount });
	}

	render() {

		return (
			<LayoutRowDiv>
				<LayoutColumnDiv Columns="twelve" Class="margin-top-25">
					<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
						<input type="hidden" name="cmd" value="_xclick"/>
						<input type="hidden" name="business" value={this.state.BusinessName} />
						<input type="hidden" name="item_name" value={this.state.ItemName} />
						<input type="hidden" name="amount" value={this.state.Amount} />
						<input type="hidden" name="buyer_credit_promo_code" value=""/>
						<input type="hidden" name="buyer_credit_product_category" value=""/>
						<input type="hidden" name="buyer_credit_shipping_method" value=""/>
						<input type="hidden" name="buyer_credit_user_address_change" value=""/>
						<input type="hidden" name="no_shipping" value="0"/>
						<input type="hidden" name="return" value="0"/>
						<input type="hidden" name="no_note" value="1"/>
						<input type="hidden" name="currency_code" value={this.state.CurrencyCode} />
						<input type="hidden" name="lc" value="GB"/>
						<input type="hidden" name="bn" value="PP-BuyNowBF"/>
						<input type="image" src={PayPalButtonImage} name="submit" alt="PayPal - The safer, easier way to pay online." style={{height: '2em'}} onClick={this.payButton} />
					</form>
				</LayoutColumnDiv>
			</LayoutRowDiv>
		);
	}
}
 
export default PayPalButton;