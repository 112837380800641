import React, { Component } from "react";

import { Includes } from "../framework/Includes";
import CustomElement from "../framework/CustomElement";
import LayoutRowDiv from "../framework/LayoutRowDiv";
import LayoutColumnDiv from "../framework/LayoutColumnDiv";
 
class MainHeaderPhotos extends Component {

	componentDidMount() {

		var IncludesObj = {Includes}.Includes;

		var MainHeaderPhoto1 = document.getElementById("main-image-1");
		var MainHeaderPhoto2 = document.getElementById("main-image-2");

		MainHeaderPhoto1.setAttribute("data-balloon", IncludesObj.MainHeaderPhoto1Text);
		MainHeaderPhoto1.setAttribute("data-balloon-pos", IncludesObj.MainHeaderPhotoPos);
		MainHeaderPhoto2.setAttribute("data-balloon", IncludesObj.MainHeaderPhoto2Text);		
		MainHeaderPhoto2.setAttribute("data-balloon-pos", IncludesObj.MainHeaderPhotoPos);
	}

	render() {

		return (
			
			<LayoutRowDiv>
				<LayoutColumnDiv Columns="four" Class="margin-top-25">
					<CustomElement Type="div" Class="image-holder" Id="main-image-1">&nbsp;</CustomElement>
				</LayoutColumnDiv>

				<LayoutColumnDiv Columns="eight" Class="margin-top-25">
					<CustomElement Type="div" Class="image-holder" Id="main-image-2">&nbsp;</CustomElement>
				</LayoutColumnDiv>
			</LayoutRowDiv>
		);
	}
}
 
export default MainHeaderPhotos;