import React, { Component } from "react";

import CustomElement from "../framework/CustomElement";
import LayoutRowDiv from "../framework/LayoutRowDiv";
import LayoutColumnDiv from "../framework/LayoutColumnDiv";
import FontAwesomeLink from "../framework/FontAwesomeLink";
import { Includes } from "../framework/Includes";

class SuccessMessage extends Component {

	render() {
		return (
			<CustomElement Type="div" Class="center-align">

				<LayoutRowDiv Class="center-align">
					<LayoutColumnDiv Columns="twelve" Class="margin-top-25 center-align">
						<CustomElement Type="h5">{this.props.ModalMessage}</CustomElement>
					</LayoutColumnDiv>
				</LayoutRowDiv>
			</CustomElement>
		);
	}
}

export default SuccessMessage;