import React, { Component } from "react";

import CustomElement from "../framework/CustomElement";
import PageTitle from "../framework/PageTitle";
import HeaderPhotos from "../framework/HeaderPhotos";
import NavLinks from "../framework/NavLinks";
import Quote from "../framework/Quote";
import MainHeaderPhotos from "../framework/MainHeaderPhotos";
import SocialLinks from "../framework/SocialLinks";
import FadeScrollPoint from "../framework/FadeScrollPoint";
import NavRoutes from "../framework/NavRoutes";

import Footer from "../components/Footer";
 
class Main extends Component {
	render() {
		return (
			<CustomElement Type="div" Class="container">

				<PageTitle />
				<HeaderPhotos />
				<NavLinks />
				<hr />
				<Quote />
				<MainHeaderPhotos />
				<SocialLinks />
				<FadeScrollPoint />

				<NavRoutes />

				<Footer />
			</CustomElement>
		);
	}
}
 
export default Main;