import React, { Component } from "react";
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

import CustomElement from "../framework/CustomElement";
import LayoutRowDiv from "../framework/LayoutRowDiv";
import LayoutColumnDiv from "../framework/LayoutColumnDiv";

import DojangPic from "../images/Dojang.jpg";
 
class BookingTypeInfo extends Component {

	constructor(props) {

		super(props);

		this.InformationRef = React.createRef();
		this.InformationLeftRef = React.createRef();
		this.InformationRightRef = React.createRef();

		this.setInformations = this.setInformations.bind(this);

		this.state = ({ Type: "", Information: { InformationLeft: "", InformationRight: "" }, IsReady: false });
	}

	componentWillReceiveProps(NewProps) {

		const Information = NewProps.Information;
		const Type = NewProps.Type;

		this.setState({ IsReady: true, Type: Type });

		if (this.state.IsReady === true) {
			
			if (Information) {
			
				this.setInformations(Information);
			}
		}
	}

	setInformations(PassedInformation) {

		const Information = PassedInformation;

		if (Information.InformationRight === "") {

			this.InformationRef.current.innerHTML = documentToHtmlString(Information.InformationLeft);
		} else {

			this.InformationLeftRef.current.innerHTML = documentToHtmlString(Information.InformationLeft);
			this.InformationRightRef.current.innerHTML = documentToHtmlString(Information.InformationRight);
		}

		if (this.state.IsReady === true) {
			
			this.setState({ Information: Information });
		}
	}

	render() {
		return (
			<LayoutRowDiv Class={this.state.Information.InformationLeft !== "" ? "show margin-top-25" : "hide"}>

				<CustomElement Type="span" Class={this.state.Information.InformationRight === "" ? "show" : "hide"}>
					<LayoutColumnDiv Columns="twelve" Class="margin-top-25">
						<CustomElement Type="span" Class={this.state.Type === "Cyber" ? "show margin-top-25" : "hide"}>
							<CustomElement Type="img" Class="image-responsive about-image" Source={DojangPic} />
						</CustomElement>
						<CustomElement Type="p" Ref={this.InformationRef} />
					</LayoutColumnDiv>
				</CustomElement>

				<CustomElement Type="span" Class={this.state.Information.InformationRight !== "" ? "show" : "hide"}>
					<LayoutColumnDiv Columns="six" Class="margin-top-25">
						<CustomElement Type="p" Class="center-align" Ref={this.InformationLeftRef} />
					</LayoutColumnDiv>

					<LayoutColumnDiv Columns="six" Class="margin-top-25">
						<CustomElement Type="p" Class="center-align" Ref={this.InformationRightRef} />
					</LayoutColumnDiv>
				</CustomElement>
			</LayoutRowDiv>
		);
	}
}
 
export default BookingTypeInfo;