import React, { Component } from "react";

import CustomElement from "../framework/CustomElement";
import LayoutRowDiv from "../framework/LayoutRowDiv";
import LayoutColumnDiv from "../framework/LayoutColumnDiv";
import FontAwesomeLink from "../framework/FontAwesomeLink";
import { Includes } from "../framework/Includes";
import BookingTypePayment from "../components/BookingTypePayment";

class PaymentModal extends Component {

	render() {
		return (
			<CustomElement Type="div" Class="center-align">
				<LayoutRowDiv Class="center-align">
					<LayoutColumnDiv Columns="twelve" Class="center-align">
						<BookingTypePayment Price={this.props.Price} Type={this.props.Type} Deposit={this.props.Deposit} LoadPaymentModal={this.props.LoadPaymentModal} />
					</LayoutColumnDiv>
				</LayoutRowDiv>
			</CustomElement>
		);
	}
}

export default PaymentModal;