import React, { Component } from "react";

import CustomElement from "../framework/CustomElement";
import LayoutRowDiv from "../framework/LayoutRowDiv";
import LayoutColumnDiv from "../framework/LayoutColumnDiv";
import FontAwesomeLink from "../framework/FontAwesomeLink";
import { Includes } from "../framework/Includes";

class InstagramImageModal extends Component {

	render() {
		return (
			<CustomElement Type="div" Class="center-align">

				<LayoutRowDiv Class="center-align">
					<LayoutColumnDiv Columns="twelve" Class="margin-top-25 center-align">
						<CustomElement Type="img" Class="image-responsive" Source={this.props.ImageURL} />
					</LayoutColumnDiv>
				</LayoutRowDiv>

				<LayoutRowDiv Class="margin-top-25 center-align">
	    			<LayoutColumnDiv Columns="twelve" Class="center-align">
	    				<CustomElement Type="h5" Class="center-align"><a href={Includes.InstagramLink} target="_blank" rel="noopener noreferrer" className="view-more-instagram-link breadcrumb-link"><FontAwesomeLink Class="fab fa-instagram" />&nbsp;{Includes.MediaViewAllOnInstagram}</a></CustomElement>
	    			</LayoutColumnDiv>
	    		</LayoutRowDiv>
			</CustomElement>
		);
	}
}

export default InstagramImageModal;