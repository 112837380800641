import React, { Component } from "react";

import { YoutubePlaylistDetails } from "../framework/YoutubePlaylistDetails";
import CustomElement from "../framework/CustomElement";
import LayoutRowDiv from "../framework/LayoutRowDiv";
import LayoutColumnDiv from "../framework/LayoutColumnDiv";
 
class Media extends Component {

	componentDidMount() {

		var AllPlaylists = document.getElementsByClassName("media-video");

		for (var i = 0; i < AllPlaylists.length; i++) {

			AllPlaylists[i].setAttribute("allow", "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture");
			AllPlaylists[i].setAttribute("allowFullScreen", null);
		}
	}

	selectPlaylist(ClickedPlaylist) {

		var ClickedPlaylistId = ClickedPlaylist.target.id;
		var AllPlaylists = document.getElementsByClassName("PlaylistContainer");
		var AllPlaylistButtons = document.getElementsByClassName("media-video-link");

		Object.keys(AllPlaylists).forEach(Key => {

    		AllPlaylists[Key].classList.add("hide");
    	});

    	Object.keys(AllPlaylistButtons).forEach(Key => {

    		AllPlaylistButtons[Key].classList.remove("button-primary");
    	});

    	ClickedPlaylist.target.classList.add("button-primary");
		document.getElementById(ClickedPlaylistId + "Playlist").classList.remove("hide");
	}

	render() {

		const self = this;

		return (

			<CustomElement Type="div" Id="YoutubePlaylistsContainer">
				<LayoutRowDiv>
	    			<LayoutColumnDiv Columns="twelve" Class="margin-top-25 center-align">

	    				{YoutubePlaylistDetails.map(function(Playlist, index) {
							return (<CustomElement Type="button" Class="button media-video-link" Id={Playlist.Id} Key={index} ClickEvent={self.selectPlaylist}>{Playlist.LinkName}</CustomElement>);
						})}
	    			</LayoutColumnDiv>
	    		</LayoutRowDiv>

	    		<LayoutRowDiv>
	    			<LayoutColumnDiv Columns="twelve" Class="margin-top-25 center-align">

	    				{YoutubePlaylistDetails.map(function(Playlist, index) {
							return (
								<CustomElement Type="div" Id={Playlist.VideoId} Class="PlaylistContainer hide margin-top-25" Key={index}>
									<CustomElement Type="h3" Class="center-align margin-top-25">{Playlist.LinkName}</CustomElement>
									<hr />
									<CustomElement Type="iframe" Source={Playlist.PlaylistLink} Class="margin-top-25 center-align twelve columns media-video" Key={index} IFrameBorder="0"></CustomElement>
								</CustomElement>
							)
						})}
	    			</LayoutColumnDiv>
	    		</LayoutRowDiv>

	    	</CustomElement>
		);
	}
}
 
export default Media;