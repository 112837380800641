import React, { Component } from 'react';

class CustomElement extends Component {
	
	constructor() {
		
		super();

		this.state = {};
	}

	render() {

		var MakeElement;
		const create = React.createElement;

		const Type = (this.props.Type === undefined) ? "" : this.props.Type;
		const Class = (this.props.Class === undefined) ? "" : this.props.Class;
		const Id = (this.props.Id === undefined) ? "" : this.props.Id;
		const Data = (this.props.Data === undefined) ? "" : this.props.Data;
		const ClickEvent = (this.props.ClickEvent === undefined) ? undefined : this.props.ClickEvent;
		const OnChange = (this.props.OnChange === undefined) ? undefined : this.props.OnChange;
		const Ref = (this.props.Ref === undefined) ? undefined : this.props.Ref;
		const Key = (this.props.Key === undefined) ? undefined : this.props.Key;
		const Value = (this.props.Value === undefined) ? undefined : this.props.Value;
		const Name = (this.props.Name === undefined) ? undefined : this.props.Name;

		const DataBalloon = (this.props.DataBalloon === undefined) ? undefined : this.props.DataBalloon;
		const DataBalloonPos = (this.props.DataBalloonPos === undefined) ? undefined : this.props.DataBalloonPos;
		
		const ChannelID = (this.props.ChannelID === undefined) ? undefined : this.props.ChannelID;
		const DataLayout = (this.props.DataLayout === undefined) ? undefined : this.props.DataLayout;
		const DataCount = (this.props.DataCount === undefined) ? undefined : this.props.DataCount;

		const DataLang = (this.props.DataLang === undefined) ? undefined : this.props.DataLang;
		const DataHeight = (this.props.DataHeight === undefined) ? undefined : this.props.DataHeight;
		const DataDNT = (this.props.DataDNT === undefined) ? undefined : this.props.DataDNT;
		const DataTheme = (this.props.DataTheme === undefined) ? undefined : this.props.DataTheme;
		const DataChrome = (this.props.DataTheme === undefined) ? undefined : this.props.DataChrome;

		const Source = (this.props.Source === undefined) ? "" : this.props.Source;
		const Content = (this.props.children === undefined) ? "" : this.props.children;
		const Hyperlink = (this.props.Hyperlink === undefined) ? "" : this.props.Hyperlink;

		const InputType = (this.props.InputType === undefined) ? "" : this.props.InputType;
		const InputPlaceholder = (this.props.InputPlaceholder === undefined) ? "" : this.props.InputPlaceholder;
		
		const ButtonType = (this.props.ButtonType === undefined) ? "" : this.props.ButtonType;
		const ButtonValue = (this.props.ButtonValue === undefined) ? "" : this.props.ButtonValue;
		const CustomButtonData = (this.props.CustomButtonData === undefined) ? "" : this.props.CustomButtonData;

		const LabelFor = (this.props.LabelFor === undefined) ? "" : this.props.LabelFor;

		const IFrameHeight = (this.props.IFrameHeight === undefined) ? "" : this.props.IFrameHeight;
		const IFrameWidth = (this.props.IFrameWidth === undefined) ? "" : this.props.IFrameWidth;
		const IFrameBorder = (this.props.IFrameBorder === undefined) ? "" : this.props.IFrameBorder;

		if (Type === "img") {

			MakeElement = create(Type, {className: Class, id: Id, data: Data, onClick: ClickEvent, src: Source, key: Key});
		} else if (Type === "input" || Type === "textarea") {

			MakeElement = create(Type, {className: Class, id: Id, data: Data, onChange: OnChange, onClick: ClickEvent, type: InputType, placeholder: InputPlaceholder, ref: Ref, key: Key});
		} else if (Type === "button") {

			MakeElement = create(Type, {className: Class, id: Id, data: Data, onClick: ClickEvent, type: ButtonType, value: ButtonValue, "custom-data": CustomButtonData, ref: Ref, key: Key}, Content);
		} else if (Type === "label") {
			
			MakeElement = create(Type, {className: Class, id: Id, htmlFor: LabelFor, ref: Ref}, Content);
		} else if (Type === "iframe") {

			MakeElement = create(Type, {className: Class, id: Id, src: Source, width: IFrameWidth, height: IFrameHeight, frameBorder: IFrameBorder, allowFullScreen: "allowfullscreen", key: Key}, Content);
		} else if (Type === "YTSub") {

			MakeElement = create("div", {className: Class, id: Id, data: Data, onClick: ClickEvent, "data-channelid": ChannelID, "data-layout": DataLayout, "data-count": DataCount, key: Key}, Content);
		} else if (Type === "TwitterFeed") {

			MakeElement = create("a", {className: Class, id: Id, data: Data, onClick: ClickEvent, "data-height": DataHeight, "data-dnt": DataDNT, "data-theme": DataTheme, "data-chrome": DataChrome, "data-lang": DataLang, href: Hyperlink, key: Key})
		} else if (Type === "select" || Type === "option") {

			MakeElement = create(Type, {className: Class, id: Id, value: Value, ref: Ref, key: Key, onChange: OnChange, name: Name, data: Data}, Content);
		} else {

			MakeElement = create(Type, {className: Class, id: Id, value: Value, data: Data, onClick: ClickEvent, ref: Ref, href: Hyperlink, key: Key, "data-balloon": DataBalloon, "data-balloon-pos": DataBalloonPos}, Content);
		}
				
	    return (

	    	MakeElement
	    );
  	}
}

export default CustomElement;