import React, { Component } from "react";
import * as contentful from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

import { Includes } from "../framework/Includes";
import CustomElement from "../framework/CustomElement";
import LayoutRowDiv from "../framework/LayoutRowDiv";
import LayoutColumnDiv from "../framework/LayoutColumnDiv";

import GeneralPhoto from "../images/generalchoi.jpg"

class About extends Component {

	constructor() {

		super();
		this.state = ({BenefitsLeft: [], BenefitsRight: []});
		this.GeneralPhoto = React.createRef();
	}

	componentDidMount() {

		var IncludesObj = {Includes}.Includes;

		this.GeneralPhoto.current.setAttribute("data-balloon", IncludesObj.AboutGeneralText);
		this.GeneralPhoto.current.setAttribute("data-balloon-pos", IncludesObj.AboutGeneralPos);

		//	Initialize Contentful
		const ContentfulClient = contentful.createClient({

			space: "ylj8exbi749r",
			accessToken: "5244c7b607d4ac81e4ad54c45b3d1a23d246325588a73a87b97233659ec4f1a5"
		});

		//	About Blurb
		ContentfulClient.getEntry("1RYHm0iLe6iO1jQ5Hq5ErU").then(Entry => {
			
			if (Entry.fields) {

				var Collection = Entry.fields;

				Object.keys(Collection).forEach(Key => {

					if (Key !== "title") {
						
						var ParsedEntry = documentToHtmlString(Collection[Key]);
						document.getElementById(Key).innerHTML = ParsedEntry;
					}
				});
			}
		});

		//	Benefits Left
		ContentfulClient.getEntry("2yTV5XR7V0gTu9LGMwOZ8h").then(Entry => {

			if (Entry.fields && Entry.fields.benefitsItems) {

				var BenefitsLeft = Entry.fields.benefitsItems;
				this.setState({BenefitsLeft: BenefitsLeft});

			}
		});

		//	Benefits Right
		ContentfulClient.getEntry("5WdY2HuIsHdDfbgEjXFnPW").then(Entry => {

			if (Entry.fields && Entry.fields.benefitsItems) {

				var BenefitsRight = Entry.fields.benefitsItems;
				this.setState({BenefitsRight: BenefitsRight});
			}
		});
	}

	render() {
		return (
			<CustomElement Type="div" Id="AboutContainer">
				<LayoutRowDiv>
	    			<LayoutColumnDiv Columns="twelve" Class="margin-top-25">
	    				<CustomElement Type="h1" Class="center-align">{Includes.AboutMainTitle}</CustomElement>
	    			</LayoutColumnDiv>
	    		</LayoutRowDiv>

	    		<LayoutRowDiv Class="margin-top-25">
	    			<LayoutColumnDiv Columns="twelve" Class="margin-top-25" Id="abouttkdblurb"></LayoutColumnDiv>
	    		</LayoutRowDiv>
	    		<hr />

	    		<LayoutRowDiv>
	    			<LayoutColumnDiv Columns="twelve" Class="margin-top-25">
	    				<CustomElement Type="h1" Class="center-align">{Includes.AboutSecondTitle}</CustomElement>
	    			</LayoutColumnDiv>
	    		</LayoutRowDiv>

	    		<LayoutRowDiv Class="margin-top-25">
	    			<LayoutColumnDiv Columns="four" Class="margin-top-25" Id="GeneralPhotoElement" Ref={this.GeneralPhoto}>
	    				<CustomElement Type="img" Class="image-responsive about-image" Source={GeneralPhoto} />
	    				<CustomElement Type="h5" Class="center-align">{Includes.AboutGeneral}<br />{Includes.AboutGeneralSub}</CustomElement>
	    			</LayoutColumnDiv>

		    		<LayoutColumnDiv Columns="four" Class="margin-top-25">
		    			<CustomElement Type="ul">
				    		{this.state.BenefitsLeft.map(function(BenefitType, index) { return <li key={index}>{BenefitType}</li> })}
						</CustomElement>
					</LayoutColumnDiv>

					<LayoutColumnDiv Columns="four" Class="margin-top-25">
		    			<CustomElement Type="ul">
				    		{this.state.BenefitsRight.map(function(BenefitType2, index2) { return <li key={index2}>{BenefitType2}</li> })}
						</CustomElement>
					</LayoutColumnDiv>
				</LayoutRowDiv>

	    	</CustomElement>
		);
	}
}
 
export default About;