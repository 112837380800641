import React, { Component } from "react";

import { Includes } from "../framework/Includes";
import FontAwesomeLink from "../framework/FontAwesomeLink";
import CustomElement from "../framework/CustomElement";
import LayoutRowDiv from "../framework/LayoutRowDiv";
import LayoutColumnDiv from "../framework/LayoutColumnDiv";
 
class SocialLinks extends Component {

	constructor(props) {

		super(props);

		this.TwitterRef = React.createRef();
		this.FacebookRef = React.createRef();
		this.InstagramRef = React.createRef();
	}

	componentDidMount() {

		var IncludesObj = {Includes}.Includes;

		this.TwitterRef.current.setAttribute("data-balloon", IncludesObj.TwitterSocialText);
		this.TwitterRef.current.setAttribute("data-balloon-pos", IncludesObj.SocialPos);

		this.FacebookRef.current.setAttribute("data-balloon", IncludesObj.FacebookSocialText);
		this.FacebookRef.current.setAttribute("data-balloon-pos", IncludesObj.SocialPos);

		this.InstagramRef.current.setAttribute("data-balloon", IncludesObj.InstagramSocialText);
		this.InstagramRef.current.setAttribute("data-balloon-pos", IncludesObj.SocialPos);
	}

	render() {

		return (
			
			<LayoutRowDiv>
				<LayoutColumnDiv Columns="twelve" Class="margin-top-25">
					<CustomElement Type="div" Class="social-div center-align">
						<CustomElement Type="span" Id="TwitterSpan" Ref={this.TwitterRef}>
							<FontAwesomeLink Class="fab fa-twitter-square" FAType="social-image" Id="TwitterLink" Data={Includes.TwitterLink} />
						</CustomElement>

						<CustomElement Type="span" Class="divider2" />

						<CustomElement Type="span" Id="FacebookSpan" Ref={this.FacebookRef}>
							<FontAwesomeLink Class="fab fa-facebook" FAType="social-image" Id="FacebookLink" Data={Includes.FacebookLink} />
						</CustomElement>

						<CustomElement Type="span" Class="divider2" />

						<CustomElement Type="span" Id="InstagramSpan" Ref={this.InstagramRef}>
							<FontAwesomeLink Class="fab fa-instagram" FAType="social-image" Id="InstagramLink" Data={Includes.InstagramLink} />
						</CustomElement>
					</CustomElement>
				</LayoutColumnDiv>
			</LayoutRowDiv>
		);
	}
}
 
export default SocialLinks;