import React, { Component } from "react";

import { Includes } from "../framework/Includes";
import CustomElement from "../framework/CustomElement";
import LayoutRowDiv from "../framework/LayoutRowDiv";
import LayoutColumnDiv from "../framework/LayoutColumnDiv";
 
class PageTitle extends Component {
	render() {

		return (
			
			<LayoutRowDiv>
				<LayoutColumnDiv Columns="twelve" Class="margin-top-25">
					<CustomElement Type="h1" Class="center-align">{Includes.MainPageTitle}</CustomElement>
					<CustomElement Type="h5" Class="center-align">{Includes.SubPageTitle}</CustomElement>
				</LayoutColumnDiv>
			</LayoutRowDiv>
		);
	}
}
 
export default PageTitle;