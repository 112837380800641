import React, { Component } from "react";

import CustomElement from "../framework/CustomElement";
import LayoutRowDiv from "../framework/LayoutRowDiv";
import LayoutColumnDiv from "../framework/LayoutColumnDiv";
 
class BookingTypeTitle extends Component {

	constructor(props) {

		super(props);

		this.state = ({ Title: "" });
	}

	componentDidMount(props) {

		const Title = this.props.Title;
		this.setState({ Title: Title });
	}

	componentWillReceiveProps(NewProps) {

		const Title = NewProps.Title;
		this.setState({ Title: Title });
	}

	render() {

		return (
			<LayoutRowDiv Class={this.state.Title !== "" ? "show" : "hide"}>
				<LayoutColumnDiv Columns="twelve" Class="margin-top-25">
					<CustomElement Type="h1" Class="center-align">{this.state.Title}</CustomElement>
					<hr />
				</LayoutColumnDiv>
			</LayoutRowDiv>
		);
	}
}
 
export default BookingTypeTitle;