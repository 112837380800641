import React, { Component } from "react";
import ReactDOM from 'react-dom';

import { Includes } from "../framework/Includes";
import FontAwesomeLink from "../framework/FontAwesomeLink";
import CustomElement from "../framework/CustomElement";
import LayoutRowDiv from "../framework/LayoutRowDiv";
import LayoutColumnDiv from "../framework/LayoutColumnDiv";
import LocationMap from "../framework/LocationMap";
import SuccessMessage from "../framework/SuccessMessage";
 
class Contact extends Component {

    constructor(props) {

        super(props);

        this.sendMessage = this.sendMessage.bind(this);
        this.showSuccessMessage = this.showSuccessMessage.bind(this);
        this.getModalDetails = this.getModalDetails.bind(this);
        this.showModalDialog = this.showModalDialog.bind(this);

        this.ContactName = React.createRef();
        this.ContactEmail = React.createRef();
        this.ContactPhone = React.createRef();
        this.ContactMessage = React.createRef();
    }

    sendMessage(event) {

        event.preventDefault();

        //  Replace this with Server URL to PHP Mail Script
        var PHPMailScriptURL = "https://cutler-tkd.co.uk/PHPScripts/ContactSectionMail.php";
        // var PHPMailScriptURL = "http://localhost:9010/mail_script_for_react.php";
        var ContactName = this.ContactName.current.value;
        var ContactEmail = this.ContactEmail.current.value;
        var ContactPhone = this.ContactPhone.current.value;
        var ContactMessage = this.ContactMessage.current.value;

        var ContactDetails = new FormData();
        ContactDetails.append("name", ContactName);
        ContactDetails.append("email", ContactEmail);
        ContactDetails.append("phone", ContactPhone);
        ContactDetails.append("message", ContactMessage);

        //  Options for the API call
        const options = { method: "POST", body: ContactDetails };

        //  API Call to php script to mail
        fetch(PHPMailScriptURL, options)
        .then(response => response.json())
        .then(data => {
            
            console.log(data);
            this.showSuccessMessage();

        });
    }

    showSuccessMessage() {

        this.showModalDialog();
    }

    getModalDetails(ModalMessage) {
            
        return ReactDOM.render(<SuccessMessage ModalMessage={ModalMessage} />, document.getElementById("modal-message"));
    }

    //    Show the modal dialog
    showModalDialog = () => {

        document.getElementById("modal-title").innerHTML = "";
        document.getElementsByClassName("modal-content-inner")[0].classList.remove("center-align");

        let ModalTitle = "Message status";
        let ModalMessage = "Your message has been successfully sent!";

        this.getModalDetails(ModalMessage);

        document.getElementsByClassName("modal")[0].classList.add("modal-open");
        document.getElementById("modal-title").innerHTML = ModalTitle;
    }

    render() {
        return (

            <CustomElement Type="div" Id="ContactContainer">
            	<LayoutRowDiv Class="margin-top-25">
            		<LayoutColumnDiv Columns="twelve" Class="margin-top-25">
            			<CustomElement Type="h1" Class="center-align">{Includes.ContactMainTitle}</CustomElement>
            		</LayoutColumnDiv>
            	</LayoutRowDiv>

                <LayoutRowDiv Class="margin-top-25">
                    <LayoutColumnDiv Columns="three" Class="margin-top-25">

                        <CustomElement Type="Label" LabelFor="contact_name_input">Full Name</CustomElement>
                        <CustomElement Type="input" Class="ui-full-width input-full-column-width" InputType="text" InputPlaceholder="Joe Bloggs" Id="contact_name_input" Ref={this.ContactName} />

                        <CustomElement Type="Label" LabelFor="contact_email_input">Email Address</CustomElement>
                        <CustomElement Type="input" Class="ui-full-width input-full-column-width" InputType="email" InputPlaceholder="test@test.com" Id="contact_email_input" Ref={this.ContactEmail} />

                        <CustomElement Type="Label" LabelFor="contact_phone_input">Phone</CustomElement>
                        <CustomElement Type="input" Class="ui-full-width input-full-column-width" InputType="tel" InputPlaceholder="" Id="contact_phone_input" Ref={this.ContactPhone} />

                        <CustomElement Type="Label" LabelFor="contact_message_input">Message</CustomElement>
                        <CustomElement Type="textarea" Class="ui-full-width input-full-column-width" InputPlaceholder="" Id="contact_message_input" Ref={this.ContactMessage} />

                        <br />
                        <CustomElement Type="button" Class="button-primary right-align" Id="contact_form_submit" ClickEvent={this.sendMessage}>Send Message</CustomElement>
                    </LayoutColumnDiv>

                    <LayoutColumnDiv Columns="three" Class="margin-top-25">
                        <CustomElement Type="span" Class="bold-text">&nbsp;{Includes.ContactName}</CustomElement>
                        <br />
                        <FontAwesomeLink Class="fas fa-phone margin-top-25" FAType="contact-icon" />
                        <CustomElement Type="span">&nbsp;<a href={Includes.ContactTelLink}>{Includes.ContactPhone}</a></CustomElement>
                        <br />
                        <FontAwesomeLink Class="fas fa-at margin-top-25" FAType="contact-icon" />
                        <CustomElement Type="span">&nbsp;<a href={Includes.ContactMailTo}>{Includes.ContactEmail}</a></CustomElement>
                        <br />
                        <FontAwesomeLink Class="far fa-address-card margin-top-25" FAType="contact-icon" />
                        <CustomElement Type="span">&nbsp;{Includes.ContactAddress}</CustomElement>
                    </LayoutColumnDiv>

                    <LayoutColumnDiv Columns="six" Class="margin-top-25">
                        <LocationMap />
                    </LayoutColumnDiv>
                </LayoutRowDiv>
            </CustomElement>
        );
    }
}
 
export default Contact;