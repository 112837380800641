import React, { Component } from "react";
import * as contentful from 'contentful';

import CustomElement from "../framework/CustomElement";
import LayoutColumnDiv from "../framework/LayoutColumnDiv";

import BookingTypeTitle from "../components/BookingTypeTitle";
import BookingTypeInfo from "../components/BookingTypeInfo";
import BookingTypePayment from "../components/BookingTypePayment";
import BookingTypeGeneralDetails from "../components/BookingTypeGeneralDetails";

class BookingType extends Component {

	constructor(props) {

		super(props);
		this.TitleRef = React.createRef();
		this.PriceRef = React.createRef();

		this.InformationRef = React.createRef();
		this.InformationLeftRef = React.createRef();
		this.InformationRightRef = React.createRef();

		this.getEntry = this.getEntry.bind(this);

		this.state = ({

			EntryId: "",
			Type: "",
			Price: 0,
			Deposit: 0,
			PaymentLink: "",
			PageTitle: "", 
			Information: {
				InformationLeft: "", 
				InformationRight: ""
			}
		});

		//	Initialize Contentful
		this.ContentfulClient = contentful.createClient({

			space: "ylj8exbi749r",
			accessToken: "5244c7b607d4ac81e4ad54c45b3d1a23d246325588a73a87b97233659ec4f1a5"
		});
	}

	componentWillReceiveProps(NewProps) {

		const EntryId = NewProps.EntryId;
		const Type = NewProps.Type;

		this.getEntry(EntryId, Type);
	}

	getEntry(EntryId, Type) {

		//	Get Details based on passed in EntryId
		this.ContentfulClient.getEntry(EntryId).then(Entry => {

			var InformationLeft, InformationRight = "";

			if (Entry.fields) {

				var Collection = Entry.fields;
				var PageTitle = Collection.pageTitle;
				var Price = Collection.price;
				var Deposit = 0;

				if (Type == "Camp") {

					Deposit = Collection.deposit;
				}

				if (Collection.informationAndDetails) {

					InformationLeft = Collection.informationAndDetails;
				} else if (Collection.information) {

					InformationLeft = Collection.information;
				} else if (Collection.informationLeft && Collection.informationRight) {

					InformationLeft = Collection.informationLeft;
					InformationRight = Collection.informationRight;
				}

				this.setState({ EntryId: EntryId, Type: Type, PageTitle: PageTitle, Deposit: Deposit, Price: Price, Information: { InformationLeft: InformationLeft, InformationRight: InformationRight } });
			}
		});
	}

	render() {

		return (
			<CustomElement Type="div" Id="BookingTypeContainer">

				<BookingTypeTitle Title={this.state.PageTitle} />
				<BookingTypeInfo Information={this.state.Information} Type={this.state.Type} />

				<BookingTypeGeneralDetails Type={this.state.Type} Price={this.state.Price} Deposit={this.state.Deposit} LoadPaymentModal={this.props.LoadPaymentModal} />
				<LayoutColumnDiv Columns="two">&nbsp;</LayoutColumnDiv>
    		</CustomElement>
		);
	}
}
 
export default BookingType;