import React, { Component } from "react";
import * as contentful from 'contentful';

import CustomElement from "../framework/CustomElement";
import LayoutRowDiv from "../framework/LayoutRowDiv";
import LayoutColumnDiv from "../framework/LayoutColumnDiv";

import PayPalButton from "../framework/PayPalButton";
 
class OneToOnePayment extends Component {

	constructor(props) {

		super(props);
		this.state = ({ CalculatedPrice: "", SubBookingTypes: ["test", "test2"]  });
		this.QuantityRef = React.createRef();
		this.BookingSubTypeRef = React.createRef();

		this.calculatePayment = this.calculatePayment.bind(this);
		this.getBookingSubTypes = this.getBookingSubTypes.bind(this);
		this.changeSelection = this.changeSelection.bind(this);

		//	Initialize Contentful
		this.ContentfulClient = contentful.createClient({

			space: "ylj8exbi749r",
			accessToken: "5244c7b607d4ac81e4ad54c45b3d1a23d246325588a73a87b97233659ec4f1a5"
		});
	}

	componentDidMount(props) {

		this.getBookingSubTypes();
	}

	componentWillReceiveProps(NewProps) {

		const ItemName = NewProps.ItemName;
		const Amount = NewProps.Amount;

		this.setState({ ItemName: ItemName, Amount: Amount });
	}

	getBookingSubTypes() {

		this.ContentfulClient.getEntry("3HzdKI5UwUd5vCRa2dJx5j").then(Entry => {

			const PaymentTypes = Entry.fields.paymentTypes;
			this.setState({ SubBookingTypes: PaymentTypes });
		});
	}

	changeSelection() {

		this.QuantityRef.current.value = 1;
		this.calculatePayment();
	}

	calculatePayment() {

		var Quantity = this.QuantityRef.current.value;
		var BookingSubTypeAmount = this.BookingSubTypeRef.current.value;
		var BookingSubType = this.BookingSubTypeRef.current.options[this.BookingSubTypeRef.current.selectedIndex].getAttribute("data");
		var Price = this.state.Price;

		if (BookingSubTypeAmount && BookingSubTypeAmount !== "" && BookingSubTypeAmount > 0) {

			Price = parseFloat(BookingSubTypeAmount);
		}

		if (Quantity == "5") {

			var Percentage = 20;
			var BasePrice = parseFloat(Quantity * Price);
			var Discount = (BasePrice / 100) * 20;
			
			var CalculatedPrice = parseFloat(BasePrice - Discount);
		} else {
			
			var CalculatedPrice = parseFloat(Quantity * Price);
			var PayPalPrice = CalculatedPrice;

			if (CalculatedPrice === 90) {

				PayPalPrice = 94;
			} else if (CalculatedPrice === 240) {

				PayPalPrice = 249;
			} else if (CalculatedPrice === 150) {

				PayPalPrice = 156.50;
			} else {

				PayPalPrice = parseFloat(parseFloat(Price / 100 * 4) + parseFloat(Price));
			}
		}

		var BookingDescription = Quantity + " x " + BookingSubType + " - " + this.state.ItemName;

		this.setState({ CalculatedPrice: CalculatedPrice, BookingDescription: BookingDescription, PayPalPrice: PayPalPrice });
	}

	render() {

		return (
			<LayoutRowDiv>
				<LayoutColumnDiv Columns="twelve">
					<CustomElement Type="label" LabelFor="QuantityInput">1 To 1 type</CustomElement>
					<CustomElement Type="select" Id="BookingSubType" Ref={this.BookingSubTypeRef} Class="ui-full-width input-full-column-width" OnChange={this.changeSelection}>
						<CustomElement Type="option">Select an option</CustomElement>
						{this.state.SubBookingTypes.map(function(SubBookingType) {
							if (SubBookingType.price) {

								return ( <CustomElement Type="option" Value={SubBookingType.price} Data={SubBookingType.type}>{SubBookingType.type}</CustomElement> );
							}
						})}
					</CustomElement>
					<br />
					<CustomElement Type="label" LabelFor="QuantityInput">How many?</CustomElement>
					<CustomElement Type="input" InputType="text" OnChange={this.calculatePayment} Id="QuantityInput" Ref={this.QuantityRef} Class="ui-full-width input-full-column-width" Value="1" />

					<CustomElement Type="h5" Class={this.state.CalculatedPrice !== "" ? "show" : "hide"}>
						<br />
		    			Payment due today: <strong>£{this.state.CalculatedPrice}</strong>
		    			<PayPalButton ItemName={this.state.BookingDescription} Amount={this.state.PayPalPrice} />
		    		</CustomElement>

		    		<CustomElement Type="p" Class={this.state.CalculatedPrice !== "" ? "show" : "hide"}>PayPal will incur an Admin Fee.</CustomElement>
		    		<br/>
		    		<CustomElement Type="p" Class={this.state.CalculatedPrice !== "" ? "show" : "hide"}>
		    			<strong>Bank Transfer</strong><br/>
						<strong>Account number:</strong> 40610281<br/>
						<strong>Sort Code:</strong> 09-01-34<br/>
						IBAN GB91ABBY09013440610281<br/>
					</CustomElement>
				</LayoutColumnDiv>
			</LayoutRowDiv>
		);
	}
}
 
export default OneToOnePayment;