import React, { Component } from "react";
 
class FadeScrollPoint extends Component {
	
	render() {
		return (

		  	<hr id="FadeScrollPoint" />
		);
	}
}
 
export default FadeScrollPoint;