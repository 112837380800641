import React, { Component } from 'react';

import CustomElement from './CustomElement.js';

class LayoutColumnDiv extends Component {
	
	constructor() {
		
		super();
		this.state = {};
	}

	render() {

		const ColumnClass = "columns";
		const Columns = (this.props.Columns === undefined) ? ColumnClass : this.props.Columns + " " + ColumnClass;
		const Class = (this.props.Class === undefined) ? Columns : Columns + " " + this.props.Class;
		const Id = (this.props.Id === undefined) ? "" : this.props.Id;
		const Ref = (this.props.Ref === undefined) ? "" : this.props.Ref;

		const Content = (this.props.children === undefined) ? "" : this.props.children;
		
	    return (

	        <CustomElement Type="div" Class={Class} Id={Id} Ref={Ref}>{Content}</CustomElement>
	    );
  	}
}

export default LayoutColumnDiv;