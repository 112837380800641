import React, { Component } from "react";

import CustomElement from "../framework/CustomElement";
import LayoutRowDiv from "../framework/LayoutRowDiv";
import LayoutColumnDiv from "../framework/LayoutColumnDiv";
import PayPalButton from "../framework/PayPalButton";

import OneToOnePayment from "../components/OneToOnePayment";
 
class BookingTypePayment extends Component {

	constructor(props) {

		super(props);
		this.state = ({ Type: "", Deposit: 0, RemainingAmount: 0, Price: "", CalculatedPrice: "", IsReady: false });
	}

	componentWillReceiveProps(NewProps) {

		const Type = NewProps.Type;
		const Deposit = NewProps.Deposit;
		const Price = NewProps.Price;
		var RemainingAmount = 0;

		if (Deposit != 0 && Deposit != "") {

			RemainingAmount = parseFloat(Price - Deposit);
		}

		var PayPalPrice = Price;
		var PayPalDeposit = Deposit;
		var PayPalRemainingAmount = RemainingAmount;

		if (Price === "310") {

			PayPalPrice = "322.40";
		} else if (Price === "210") {

			PayPalPrice = "218.40";
		} else if (Price === "100") {

			PayPalPrice = "104";
		} else {

			PayPalPrice = parseFloat(parseFloat(Price / 100 * 4) + parseFloat(Price));
		}

		if (Deposit === "310") {

			PayPalDeposit = "322.40";
		} else if (Deposit === "210") {

			PayPalDeposit = "218.40";
		} else if (Deposit === "100") {

			PayPalDeposit = "104";
		} else {

			PayPalDeposit = parseFloat(parseFloat(Price / 100 * 4) + parseFloat(Price));
		}

		if (RemainingAmount === "310" || RemainingAmount === 310) {

			PayPalRemainingAmount = "322.40";
		} else if (RemainingAmount === "210" || RemainingAmount === 210) {

			PayPalRemainingAmount = "218.40";
		} else if (RemainingAmount === "100" || RemainingAmount === 100) {

			PayPalRemainingAmount = "104";
		} else {

			PayPalRemainingAmount = parseFloat(parseFloat(Price / 100 * 4) + parseFloat(Price));
		}
		
		this.setState({ Type: Type, Deposit: Deposit, RemainingAmount: RemainingAmount, Price: Price, PayPalPrice: PayPalPrice, PayPalDeposit: PayPalDeposit, PayPalRemainingAmount: PayPalRemainingAmount, IsReady: true });
	}

	render() {

		if (this.props.LoadPaymentModal === true) {

			const Type = this.props.Type;
			const Deposit = this.props.Deposit;
			const Price = this.props.Price;
			var RemainingAmount = 0;

			if (Deposit != 0 && Deposit != "") {

				RemainingAmount = parseFloat(Price - Deposit);
			}

			var PayPalPrice = Price;
			var PayPalDeposit = Deposit;
			var PayPalRemainingAmount = RemainingAmount;

			if (Price === "310") {

				PayPalPrice = "322.40";
			} else if (Price === "210") {

				PayPalPrice = "218.40";
			} else if (Price === "100") {

				PayPalPrice = "104";
			} else {

				PayPalPrice = parseFloat(parseFloat(Price / 100 * 4) + parseFloat(Price));
			}

			if (Deposit === "310") {

				PayPalDeposit = "322.40";
			} else if (Deposit === "210") {

				PayPalDeposit = "218.40";
			} else if (Deposit === "100") {

				PayPalDeposit = "104";
			} else {

				PayPalDeposit = parseFloat(parseFloat(Price / 100 * 4) + parseFloat(Price));
			}

			if (RemainingAmount === "310" || RemainingAmount === 310) {

				PayPalRemainingAmount = "322.40";
			} else if (RemainingAmount === "210" || RemainingAmount === 210) {

				PayPalRemainingAmount = "218.40";
			} else if (RemainingAmount === "100" || RemainingAmount === 100) {

				PayPalRemainingAmount = "104";
			} else {

				PayPalRemainingAmount = parseFloat(parseFloat(Price / 100 * 4) + parseFloat(Price));
			}

			return (
				<LayoutRowDiv Class={Price !== "" ? "show" : "hide"}>
					<LayoutColumnDiv Columns="twelve" Class={Type === "OneToOne" || Type === "Cyber" ? "show margin-top-25" : "hide"}>

						<OneToOnePayment ItemName={Type} />
					</LayoutColumnDiv>

					<LayoutColumnDiv Columns="twelve" Class={Type !== "OneToOne" && Type !== "Cyber" ? "show" : "hide"}>

						<CustomElement Type="h5" Class={Price !== "" ? "show" : "hide"}>
			    			Deposit: <strong>£{Deposit}</strong>
			    			<PayPalButton ItemName="Deposit" Amount={PayPalDeposit} />

			    			Remaining Amount: <strong>£{RemainingAmount}</strong>
			    			<PayPalButton ItemName="Remaining Amount" Amount={PayPalRemainingAmount} />

			    			Full Amount: <strong>£{Price}</strong>
			    			<PayPalButton ItemName="Full Amount" Amount={PayPalPrice} />
			    		</CustomElement>

			    		<CustomElement Type="p" Class={Price !== "" ? "show" : "hide"}>PayPal will incur an Admin Fee.</CustomElement>
			    		<br/>
			    		<CustomElement Type="p" Class={Price !== "" ? "show" : "hide"}>
			    			<strong>Bank Transfer</strong><br/>
							<strong>Account number:</strong> 40610281<br/>
							<strong>Sort Code:</strong> 09-01-34<br/>
							IBAN GB91ABBY09013440610281<br/>
						</CustomElement>
					</LayoutColumnDiv>
				</LayoutRowDiv>
			);
		} else {

			return (
				<LayoutRowDiv Class={this.state.Price !== "" ? "show" : "hide"}>
					<LayoutColumnDiv Columns="twelve" Class={this.state.Type === "OneToOne" || this.state.Type === "Cyber" ? "show margin-top-25" : "hide"}>

						<OneToOnePayment ItemName={this.state.Type} />
					</LayoutColumnDiv>

					<LayoutColumnDiv Columns="twelve" Class={this.state.Type !== "OneToOne" && this.state.Type !== "Cyber" ? "show" : "hide"}>

						<CustomElement Type="h5" Class={this.state.Price !== "" ? "show" : "hide"}>
			    			Deposit: <strong>£{this.state.Deposit}</strong>
			    			<PayPalButton ItemName="Deposit" Amount={this.state.PayPalDeposit} />

			    			Remaining Amount: <strong>£{this.state.RemainingAmount}</strong>
			    			<PayPalButton ItemName="Remaining Amount" Amount={this.state.PayPalRemainingAmount} />

			    			Full Amount: <strong>£{this.state.Price}</strong>
			    			<PayPalButton ItemName="Full Amount" Amount={this.state.PayPalPrice} />
			    		</CustomElement>

			    		<CustomElement Type="p" Class={this.state.Price !== "" ? "show" : "hide"}>PayPal will incur an Admin Fee.</CustomElement>
			    		<br/>
			    		<CustomElement Type="p" Class={this.state.Price !== "" ? "show" : "hide"}>
			    			<strong>Bank Transfer</strong><br/>
							<strong>Account number:</strong> 40610281<br/>
							<strong>Sort Code:</strong> 09-01-34<br/>
							IBAN GB91ABBY09013440610281<br/>
						</CustomElement>
					</LayoutColumnDiv>
				</LayoutRowDiv>
			);			
		}
	}
}
 
export default BookingTypePayment;