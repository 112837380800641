import React, { Component } from "react";
import ReactDOM from 'react-dom';

import { Includes } from "../framework/Includes";
import FontAwesomeLink from "../framework/FontAwesomeLink";
import CustomElement from "../framework/CustomElement";
import LayoutRowDiv from "../framework/LayoutRowDiv";
import LayoutColumnDiv from "../framework/LayoutColumnDiv";
import InstagramImageModal from "../framework/InstagramImageModal";
 
class InstagramFeed extends Component {

	constructor(props) {

		super(props);

		this.parseInstagramData = this.parseInstagramData.bind(this);
		this.openInstagramImage = this.openInstagramImage.bind(this);
		this.getModalDetails = this.getModalDetails.bind(this);
		this.showModalDialog = this.showModalDialog.bind(this);
		this.state = ({InstagramData: "", FetchingDataFinished: false});
	}

	componentDidMount() {

		// const AccessToken = "8483878422.1677ed0.f6e4ffeb4c2f4f09872622797e4259f2";
		// const UserId = "8483878422";
		// const NumPhotos = 5;
		// const APIURL = "https://cors-anywhere.herokuapp.com/https://api.instagram.com/v1/users/" + UserId + "/media/recent?access_token=" + AccessToken + "&count=" + NumPhotos + "&callback=?";

		// //  Options for the API call
		// fetch(APIURL)
		// .then(response => response.json())
		// .then(result => {
			
		// 	this.setState({InstagramData: result.data, FetchingDataFinished: true}); 
		// 	this.parseInstagramData();
		// });
	}

	parseInstagramData() {

		if (this.state.FetchingDataFinished === true) {

			var ImagesDiv = document.getElementById("image-gallery");

			const InstagramDataRetrieved = this.state.InstagramData;

			Object.keys(InstagramDataRetrieved).forEach(Key => {

    			const Caption = InstagramDataRetrieved[Key].caption.text;
    			const ThumbnailImageURL = InstagramDataRetrieved[Key].images.thumbnail.url;
    			const FullImageURL = InstagramDataRetrieved[Key].images.standard_resolution.url;

    			var ImageSpan = document.createElement("span");
    			var ImageFile = document.createElement("img");

				ImageFile.src = ThumbnailImageURL;
				ImageFile.title = Caption;
				ImageFile.classList.add("padding-right-image");
				ImageFile.classList.add("small-gallery-image");
				ImageFile.classList.add("instagram-image");
				ImageFile.setAttribute("data", FullImageURL);
				
				var self = this;
				ImageFile.onclick = function(e) { self.openInstagramImage(e); };

				ImageSpan.appendChild(ImageFile);
				ImagesDiv.appendChild(ImageSpan);
    		});

		} else {

			console.log("Fetching Instagram Data");
		}
	}

	openInstagramImage(event) {

		const ImageTitle = event.target.title;
		const ImageSource = event.target.getAttribute("data");

		this.showModalDialog(ImageTitle, ImageSource, "far fa-images");
	}

	getModalDetails(ImageTitle, ImageURL) {
			
		return ReactDOM.render(<InstagramImageModal ImageTitle={ImageTitle} ImageURL={ImageURL} />, document.getElementById("modal-message"));
	}

	//	Show the modal dialog
	showModalDialog = (ImageTitle, ImageURL, Icon) => {

		document.getElementById("modal-title").innerHTML = "";
		document.getElementsByClassName("modal-content-inner")[0].classList.remove("center-align");

		let ModalTitle = "View Instagram Image";

		this.getModalDetails(ImageTitle, ImageURL);

		document.getElementsByClassName("modal")[0].classList.add("modal-open");
		document.getElementById("modal-title").innerHTML = ModalTitle;
	}

	render() {
		return (

			<CustomElement Type="div" Id="ContentfulTestContainer">

				<LayoutRowDiv>
	    			<LayoutColumnDiv Columns="twelve" Class="margin-top-25">
	    				<CustomElement Type="h1" Class="center-align">{Includes.MediaInstagramTitle}</CustomElement>
	    			</LayoutColumnDiv>
	    		</LayoutRowDiv>

	    		<LayoutRowDiv>
	    			<LayoutColumnDiv Columns="twelve">
	    				<CustomElement Type="h5" Class="center-align"><i>{Includes.MediaInstagramSubTitle}</i></CustomElement>
	    			</LayoutColumnDiv>
	    		</LayoutRowDiv>

				<LayoutRowDiv Class="margin-top-25">
					<LayoutColumnDiv Columns="twelve" Class="margin-top-25 center-align inline-style" Id="image-gallery">

						<iframe src='https://www.juicer.io/api/feeds/gmcutler310/iframe' frameborder='0' width='100%' height='500' style={{ display: "block", margin: "0 auto" }}></iframe>
					</LayoutColumnDiv>
				</LayoutRowDiv>

				<LayoutRowDiv Class="margin-top-25">
	    			<LayoutColumnDiv Columns="twelve">
	    				<CustomElement Type="h5" Class="center-align"><a href={Includes.InstagramLink} target="_blank" rel="noopener noreferrer" className="view-more-instagram-link breadcrumb-link"><FontAwesomeLink Class="fab fa-instagram" />&nbsp;{Includes.MediaViewAllOnInstagram}</a></CustomElement>
	    			</LayoutColumnDiv>
	    		</LayoutRowDiv>

	    	</CustomElement>
		);
	}
}
 
export default InstagramFeed;