import React, { Component } from "react";
import * as contentful from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

import { Includes } from "../framework/Includes";
import CustomElement from "../framework/CustomElement";
import LayoutRowDiv from "../framework/LayoutRowDiv";
import LayoutColumnDiv from "../framework/LayoutColumnDiv";

import PunchImage from "../images/Paulpunch.jpg";
import PaulMeditationImage from "../images/PaulMedi.jpg";
 
class GMCutler extends Component {

	constructor(props) {

		super(props);

		this.PaulPunch = React.createRef();
		this.PaulMeditation = React.createRef();
	}

	componentDidMount() {

		var IncludesObj = {Includes}.Includes;

		this.PaulPunch.current.setAttribute("data-balloon", IncludesObj.GMCutlerImageText);
		this.PaulPunch.current.setAttribute("data-balloon-pos", IncludesObj.GMCutlerImagePos);

		this.PaulMeditation.current.setAttribute("data-balloon", IncludesObj.GMCutlerImage2Text);
		this.PaulMeditation.current.setAttribute("data-balloon-pos", IncludesObj.GMCutlerImagePos);

		//	Initialize Contentful
		const ContentfulClient = contentful.createClient({

			space: "ylj8exbi749r",
			accessToken: "5244c7b607d4ac81e4ad54c45b3d1a23d246325588a73a87b97233659ec4f1a5"
		});

		ContentfulClient.getEntry("5Y3TVvB8t4aviRdyu5pVb8").then(Entry => {
			
			if (Entry.fields) {

				var Collection = Entry.fields;

				Object.keys(Collection).forEach(Key => {

					if (Key !== "title") {
						
						var ParsedEntry = documentToHtmlString(Collection[Key]);
						document.getElementById(Key).innerHTML = ParsedEntry;
					}
				});
			}
		});
	}

	render() {
		return (
			<CustomElement Type="div" Id="GMCutlerContainer">
				<LayoutRowDiv>
	    			<LayoutColumnDiv Columns="twelve">
	    				<CustomElement Type="h1" Class="margin-top-25 center-align">{Includes.GMCutlerMainTitle}</CustomElement>
	    			</LayoutColumnDiv>
	    		</LayoutRowDiv>

	    		<LayoutRowDiv Class="margin-top-25">
	    			<LayoutColumnDiv Columns="four" Class="margin-top-25" Id="PaulPunchElement" Ref={this.PaulPunch}>
	    				<CustomElement Type="img" Class="image-responsive gm-cutler-image" Source={PunchImage} />
	    			</LayoutColumnDiv>

	    			<LayoutColumnDiv Columns="eight" Class="margin-top-25" Id="blurb1top"></LayoutColumnDiv>
	    		</LayoutRowDiv>

	    		<LayoutRowDiv Class="margin-top-25">
	    			<LayoutColumnDiv Columns="twelve" Class="margin-top-25">
    					<CustomElement Type="h4" Class="center-align">{Includes.GMCutlerSecondTitle}</CustomElement>
    					<hr />
    					<CustomElement Type="span" Class="GMCutlerContent" Id="blurb2middle"></CustomElement>
	    			</LayoutColumnDiv>
	    		</LayoutRowDiv>

	    		<LayoutRowDiv>
	    			<LayoutColumnDiv Columns="four" Id="PaulMeditationElement" Ref={this.PaulMeditation}>
	    				<CustomElement Type="img" Class="image-responsive gm-cutler-image" Source={PaulMeditationImage} />
	    			</LayoutColumnDiv>

	    			<LayoutColumnDiv Columns="eight" Id="blurb3bottom"></LayoutColumnDiv>
	    		</LayoutRowDiv>
    		</CustomElement>
		);
	}
}
 
export default GMCutler;