export const Includes = {

	MainPageTitle: "PAUL CUTLER",
	SubPageTitle: "TAEKWON-DO ACADEMY",

	MainPageQuote: " The Paul Cutler Taekwon-Do Academy is the pinnacle of training; there are no short cuts to get results. Only blood sweat and tears will achieve the ultimate goal. ",

	TwitterLink: "http://twitter.com/pc_taekwondo",
	FacebookLink: "http://facebook.com/paulcutlertaekwondoacademy",
	InstagramLink: "https://www.instagram.com/gmcutler310",

	MainHeaderPhoto1Text: "Grand Master Paul Cutler",
	MainHeaderPhoto2Text: "Dojang @ Paul Cutler Taekwon-Do Academy",
	MainHeaderPhotoPos: "down",

	TwitterSocialText: "Twitter",
	FacebookSocialText: "Facebook",
	InstagramSocialText: "Instagram",
	SocialPos: "down",

	HomeMainTitle: "Introduction",
	HomeSecondTitle: "Location & Twitter",

	HomeBlurb1: "Paul Cutler is the first ever student in the United Kingdom to be promoted to 9th Dan Grandmaster. Grandmaster Cutler has been studying Taekwon-Do for over 40 years, his mentor and career long instructor is the legendary First Grandmaster Rhee Ki Ha (FGMR), known as the Father of British and European Taekwon-Do.",
	HomeBlurb2: "Grandmaster Cutler teaches authentic ITF Taekwon-Do as formulated by Taekwon-Do's founder, the late General Choi Hong Hi. The complete Martial Art, its beliefs customs, culture, method and ideology, all of which all have equal importance as the physical movements; for without these Taekwon-Do's true identity would be lost.",
	HomeBlurb3: "Grandmaster Cutler has been honoured and awarded best instructor in Britain by the founder of Taekwon-Do General Choi Hong Hi on several occasions and recieved the outstanding instructor award in 1995. Grandmaster Cutler attended over 30 international courses with General Choi Hong Hi and peronally assisted General Choi on seminars in Europe and Australia.",

	AboutMainTitle: "About Taekwon-Do",
	AboutSecondTitle: "Benefits from Taekwon-Do training",
	AboutGeneral: "General Choi Hong Hi",
	AboutGeneralSub: "1918 - 2002",
	AboutGeneralText: "General Choi Hong Hi - 1918-2002",
	AboutGeneralPos: "down",

	AboutBlurb: "Taekwon-Do is an art of self defence founded by a Korean General named Choi Hong Hi. Taekwon-Do was officially recognized on 11th April 1955 when General Choi after exhaustive researching, developing, and experimenting, proclaimed Taekwon-Do to the world as a completely valid and unique martial art having a basic Korean origin.\n\nTranslated literally ‘Tae’ stands for jumping or flying, to kick or smash with the foot. ‘Kwon’ denotes the fist - chiefly to punch or destroy with the hand or fist. ‘Do’ means an art or way - the right way built and paved by saints and sages of the past.\n\nTaekwon-Do is a martial art that has no equal in either power or technique, which has been developed using the principles of modern science. It provides the practitioner with a fine weapon to defend him or herself. Used wrongly, Taekwon-Do can be a lethal weapon. Therefore mental training must always be stressed to prevent the student from misusing it and to promote a strong sense of justice, fortitude, humility and resolve.",

	AboutBlurb1: "Taekwon-Do is an art of self defence founded by a Korean General named Choi Hong Hi. Taekwon-Do was officially recognized on 11th April 1955 when General Choi after exhaustive researching, developing, and experimenting, proclaimed Taekwon-Do to the world as a completely valid and unique martial art having a basic Korean origin.",
	AboutBlurb2: "Translated literally ‘Tae’ stands for jumping or flying, to kick or smash with the foot. ‘Kwon’ denotes the fist - chiefly to punch or destroy with the hand or fist. ‘Do’ means an art or way - the right way built and paved by saints and sages of the past.",
	AboutBlurb3: "Taekwon-Do is a martial art that has no equal in either power or technique, which has been developed using the principles of modern science. It provides the practitioner with a fine weapon to defend him or herself. Used wrongly, Taekwon-Do can be a lethal weapon. Therefore mental training must always be stressed to prevent the student from misusing it and to promote a strong sense of justice, fortitude, humility and resolve.",

	GMCutlerMainTitle: "GM Cutler",
	GMCutlerSecondTitle: "Why did I start Taekwon-Do?",

	GMCutlerImageText: "GM Paul Cutler - In his Dojang",
	GMCutlerImagePos: "down",

	GMCutlerImage2Text: "GM Paul Cutler - Meditating",

	GMCutlerBlurb1: "I have been training in Taekwon-Do for more than half my life, I started in 1973 at the Polytechnic TKD club in Oxford, England. The Chief Instructor for Great Britain was Grandmaster Rhee Ki Ha (then 6th Dan). We were members of the United Kingdom Taekwon-Do Association (UKTA) which was the ITF representative in Great Britain. I was a member of the ITF from day one and have never had any other affiliations or breaks in my membership. All of my gradings have been under Grandmaster Rhee.",

	GMCutlerBlurb2: "I had an interest in martial arts, and in particular the mystique surrounding them. However, finding a class was quite difficult as there was no advertising for any clubs in those days. I knew there were clubs in the University - but they were a closed shop. It was quite by accident I saw a work mate training during his break, he told me about Taekwon-Do and invited me along to the club. The instructor said the right things to me and got me hooked.\n\nNowadays, Taekwon-Do is my life, I do something everyday, I have the first hour of the day performing a little routine to keep me flexible and in shape, I usually get to practice all patterns twice weekly in the correct manner, not just the choreography (as so many people perform them these days).\n\nI have trained with many legends in TKD, in particular the late founder General Choi Hong Hi, in fact I have attended over 30 International courses conducted by him. Since first training under him in the early 80’s I trained at least once a year and on many occasions it was 2 or 3 times, it really wasn't difficult as he was often somewhere in Europe.\n\nI have always and still do enjoy working and training with Grandmaster Rhee, he has so much knowledge and something comes out every seminar, but, you have to listen to what he says as it can so easily go over your head.\n\n",

	GMCutlerBlurb3: "I went to Australia for a while, I really enjoyed it there, it’s a fantastic place. I learned a lot about myself and Taekwon-Do during that time, because I was the most senior person there and had no one to consult with, I had to do a lot of cross-reference study in order to understand certain aspects. Therefore, my Taekwon-Do really matured which can only be an advantage for everyone.\n\nI hope one day to see all the TKD groups in the world working under one banner instead of bickering and jostling for personal ego and power. A dream? Well maybe, but if enough people want something bad enough it can happen. Let’s work together and fulfil General Choi's ultimate dream.\n\nTaekwon-Do has enabled me to do things with my life and to become someone I could have never been without it. I would like to thank my Instructor and mentor Grand Master Rhee Ki Ha 9th Dan, for making this possible.",

	MediaMainTitle: "Media",
	MediaSecondTitle: "Pick a playlist below",
	MediaInstagramTitle: "Latest Photos",
	MediaInstagramSubTitle: "From Instagram",
	MediaViewAllOnInstagram: "View more on instagram →",

	YoutubeChannelID: "UC5VI8m-u_4sD0kOG0KFC8MA",

	MediaButton1: "Technical Advice",
	MediaButton2: "Power Breaking",
	MediaButton3: "Physical Development",

	InstagramAccessToken: "8483878422.1677ed0.f6e4ffeb4c2f4f09872622797e4259f2",

	ContactMainTitle: "Contact",

	ContactName: "Grandmaster Paul Cutler IX Dan",
	ContactPhone: "07828197327",
	ContactTelLink: "tel:07828197327",
	ContactEmail: "contact@cutler-tkd.co.uk",
	ContactMailTo: "mailto:contact@cutler-tkd.co.uk",
	ContactAddress: "14 Cairnmore Drive, Lisburn, BT28 2DP",

	FooterCopyrightText: "Copyright © Paul Cutler Taekwon-Do Academy",

};

export const Benefits1 = [
"Improve Strength, Flexibility and Balance",
"Tone and Build long, lean muscles without bulk",
"Challenge deep abdominal muscles to support the core",
"Engage mind and enhance body awareness",
"Condition efficient patterns of movement making the body less prone to injury",
"Reduce stress, relieve tension",
"Boost energy through deep stretching",
"Restore postural alignment",
"Create a stronger more flexible spine",
];

export const Benefits2 = [
"Promote recovery from strain or injury",
"Increase joint range and movement",
"Improve circulation",
"Heighten neuromuscular coordination",
"Offer relief from back pain and joint stress",
"Correct over training of muscle groups which can lead to stress or injury",
"Enhance mobility, agility and stamina",
"Compliant sports training and develop functional fitness for daily life activity",
"Improve the way your body looks and feels",
];