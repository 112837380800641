import React, { Component } from "react";
import ReactDOM from 'react-dom';

import CustomElement from "../framework/CustomElement";
import LayoutColumnDiv from "../framework/LayoutColumnDiv";
import FormElement from "../framework/FormElement";
import PaymentModal from "../framework/PaymentModal";
 
class BookingTypeGeneralDetails extends Component {

	constructor(props) {

		super(props);

		this.state = ({ Type: "", Deposit: 0, Price: "", Grades: ["10th KUP", "9th KUP", "8th KUP", "7th KUP", "6th KUP", "5th KUP", "4th KUP", "3rd KUP", "2nd KUP", "1st KUP", "1st DAN", "2nd DAN", "3rd DAN", "4th DAN", "5th DAN", "6th DAN", "7th DAN", "8th DAN", "9th DAN"] });

		this.confirmBookingDetails = this.confirmBookingDetails.bind(this);
		this.showModalDialog = this.showModalDialog.bind(this);
		this.getModalDetails = this.getModalDetails.bind(this);
		this.validateForm = this.validateForm.bind(this);
		this.sendMessage = this.sendMessage.bind(this);
		this.resetForm = this.resetForm.bind(this);

		this.Name = React.createRef();
		this.Email = React.createRef();
		this.Phone = React.createRef();
		this.Grade = React.createRef();
		this.Message = React.createRef();
	}

	componentWillReceiveProps(NewProps) {

		const Type = NewProps.Type;
		const Deposit = NewProps.Deposit;
		const Price = NewProps.Price;
		const LoadPaymentModal = NewProps.LoadPaymentModal;

		this.setState({ Type: Type, Deposit: Deposit, Price: Price, LoadPaymentModal: LoadPaymentModal });

		if (this.state.LoadPaymentModal == true && Type !== "" && Price !== "") {

			console.log(NewProps);

			const ModalTitle = "Payment";
			this.showModalDialog(ModalTitle, NewProps.Type, NewProps.Deposit, NewProps.Price);
			this.showModalDialog(ModalTitle, NewProps.Type, NewProps.Deposit, NewProps.Price);
			this.showModalDialog(ModalTitle, NewProps.Type, NewProps.Deposit, NewProps.Price);
		} else {

			console.log("NOT");
		}
	}

	resetForm() {

		this.Name.current.value = "";
		this.Email.current.value = "";
		this.Phone.current.value = "";
		this.Grade.current.value = "";
		this.Message.current.value = "";
	}

	confirmBookingDetails() {

		var Errors = this.validateForm();

		if (Errors > 0) {

			alert("Please fill out all details!");
		} else {

			this.sendMessage();			
		}
	}

	validateForm() {
		
		var Errors = 0;

		if (this.Name.current.value === "") {

			Errors++;
		}

		if (this.Email.current.value === "") {

			Errors++;
		}

		if (this.Phone.current.value === "") {

			Errors++;
		}

		if (this.Grade.current.value === "") {

			Errors++;
		}

		return Errors;
	}

	sendMessage() {

        //  Replace this with Server URL to PHP Mail Script
        var PHPMailScriptURL = "https://cutler-tkd.co.uk/PHPScripts/BookingSectionScript.php";
        // var PHPMailScriptURL = "http://localhost:9010/mail_script_for_react.php";
        var ContactName = this.Name.current.value;
        var ContactEmail = this.Email.current.value;
        var ContactPhone = this.Phone.current.value;
        var ContactGrade = this.Grade.current.value;
        var ContactMessage = this.Message.current.value;
        var TypeOfBooking = this.state.Type;

        var ContactDetails = new FormData();
        ContactDetails.append("name", ContactName);
        ContactDetails.append("email", ContactEmail);
        ContactDetails.append("phone", ContactPhone);
        ContactDetails.append("grade", ContactGrade);
        ContactDetails.append("message", ContactMessage);
        ContactDetails.append("booking_type", TypeOfBooking);

        //  Options for the API call
        const options = { method: "POST", body: ContactDetails };

        //  API Call to php script to mail
        fetch(PHPMailScriptURL, options)
        .then(response => response.json())
        .then(data => {
            
            this.resetForm();
        	const ModalTitle = "Payment";
			this.showModalDialog(ModalTitle);
        });
    }

	getModalDetails(Title, PassedType, PassedDeposit, PassedPrice) {

		const Type = (this.state.LoadPaymentModal === false) ? this.state.Type : PassedType;
		const Deposit = (this.state.LoadPaymentModal === false) ? this.state.Deposit : PassedDeposit;
		const Price = (this.state.LoadPaymentModal === false) ? this.state.Price : PassedPrice;

		return (
			ReactDOM.render(<PaymentModal ModalTitle={Title} Type={Type} Deposit={Deposit} Price={Price} LoadPaymentModal={this.state.LoadPaymentModal} />, document.getElementById("modal-message")),
			ReactDOM.render(<PaymentModal ModalTitle={Title} Type={Type} Deposit={Deposit} Price={Price} LoadPaymentModal={this.state.LoadPaymentModal} />, document.getElementById("modal-message"))
		);
	}

	//	Show the modal dialog
	showModalDialog = (ModalTitle, PassedType, PassedDeposit, PassedPrice) => {

		document.getElementById("modal-title").innerHTML = "";
		document.getElementsByClassName("modal-content-inner")[0].classList.remove("center-align");

		this.getModalDetails(ModalTitle, PassedType, PassedDeposit, PassedPrice);

		document.getElementsByClassName("modal")[0].classList.add("modal-open");
		document.getElementById("modal-title").innerHTML = ModalTitle;
	}

	render() {
		return (
				<CustomElement Type="div" Id="GeneralDetailsContainer" Class={this.state.Type !== "" && this.state.Type !== "General" ? "show" : "hide"}>
					<LayoutColumnDiv Columns="four" Class="margin-top-25">
						<FormElement ElementName="NameInput" DisplayName="Full Name" ElementType="input" Ref={this.Name} />
						<FormElement ElementName="EmailInput" DisplayName="Email" ElementType="input" Ref={this.Email} />
						<FormElement ElementName="PhoneInput" DisplayName="Phone" ElementType="input" Ref={this.Phone} />
						<CustomElement Type="label" LabelFor="Grade">Grade</CustomElement>
						<CustomElement Type="select" Name="Grade" Id="Grade" Class="ui-full-width input-full-column-width" Ref={this.Grade}>
							<CustomElement Type="option" Value="">Select an option</CustomElement>
							{this.state.Grades.map(function(Grade, i) {
								return (
									<CustomElement Type="option" Value={Grade} Key={i}>{Grade}</CustomElement>
								);
							})}
						</CustomElement>
						<FormElement ElementName="MeassageInput" DisplayName="Message" ElementType="textarea" Ref={this.Message} />

						<CustomElement Type="button" Class="button-success" Id="SubmitContactDetailsButton" ClickEvent={this.confirmBookingDetails}>Submit</CustomElement>
					</LayoutColumnDiv>
				</CustomElement>
		);
	}
}
 
export default BookingTypeGeneralDetails;