import React, { Component } from "react";

import CustomElement from "../framework/CustomElement";
import LayoutRowDiv from "../framework/LayoutRowDiv";
import LayoutColumnDiv from "../framework/LayoutColumnDiv";

import HeaderLogo1 from "../images/NewLogo1.png";
import HeaderLogo2 from "../images/NewLogo2.png";
 
class HeaderPhotos extends Component {

	render() {

		return (
			
			<LayoutRowDiv>
				<LayoutColumnDiv Columns="twelve" Class="margin-top-25 center-align">
					<CustomElement Type="img" Source={HeaderLogo1} Class="header-logo" />
					<CustomElement Type="img" Source={HeaderLogo2} Class="header-logo" />
				</LayoutColumnDiv>
			</LayoutRowDiv>
		);
	}
}
 
export default HeaderPhotos;