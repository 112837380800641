import React, { Component } from "react";
import { NavLink, HashRouter } from "react-router-dom";

import { NavLinkDetails } from "../framework/NavLinkDetails";
import LayoutRowDiv from "../framework/LayoutRowDiv";
import LayoutColumnDiv from "../framework/LayoutColumnDiv";
 
class NavLinks extends Component {

	animateClick() {

		var ScrollPointOffsetTop = document.getElementById("FadeScrollPoint").offsetTop;
		window.scroll({top: ScrollPointOffsetTop, left: 0, behavior: 'smooth'})
	}

	render() {

		const self = this;

		return (
			<HashRouter>
				<LayoutRowDiv Class="margin-top-25">
					<LayoutColumnDiv Columns="twelve" Class="margin-top-25 center-align">

						{NavLinkDetails.map(function(Link, index) {
							return <NavLink id="NavLink" className="breadcrumb-link" exact to={Link.LinkPath} key={index} onClick={self.animateClick}>{Link.LinkName}</NavLink>
						})}

					</LayoutColumnDiv>
				</LayoutRowDiv>
			</HashRouter>
		);
	}
}
 
export default NavLinks;