import Home from "../components/Home";
import About from "../components/About";
import GMCutler from "../components/GMCutler";
import Bookings from "../components/Bookings";
import Media from "../components/Media";
import Contact from "../components/Contact";

export const NavLinkDetails = [

	{"LinkName": "Home", "LinkPath": "/", "Component": Home},
	{"LinkName": "About Taekwon-Do", "LinkPath": "/about", "Component": About},
	{"LinkName": "GM Cutler", "LinkPath": "/gmculter", "Component": GMCutler},
	{"LinkName": "Training", "LinkPath": "/training", "Component": Bookings},
	{"LinkName": "Media", "LinkPath": "/media", "Component": Media},
	{"LinkName": "Contact", "LinkPath": "/contact", "Component": Contact}

]