import React, { Component } from "react";

import CustomElement from "../framework/CustomElement";
import LayoutRowDiv from "../framework/LayoutRowDiv";
import LayoutColumnDiv from "../framework/LayoutColumnDiv";
import BookingType from "../components/BookingType";

class Bookings extends Component {

	constructor(props) {

		super(props);

		this.state = ({ SelectedEntryId: "", Type: "", IsReady: false, LoadPaymentModal: false });

		this.BookingTypes = [
			{ EntryId: "319b8YkiKv8HCARXiEmIZZ", Title: "Training Camp", Type: "Camp" },
			{ EntryId: "2jVbBx54GGYObda0hd197F", Title: "Personal Tuition", Type: "OneToOne" },
			{ EntryId: "2hXUopAmtfsffUJqBxP1zh", Title: "Cyber Dojang", Type: "Cyber" },
			{ EntryId: "2JTzEfK2Jx5fGLf2jRcbIu", Title: "General Classes", Type: "General" },
		];

		this.selectBookingType = this.selectBookingType.bind(this);
		this.fetchGETVars = this.fetchGETVars.bind(this);
		this.simulateSelectBookingType = this.simulateSelectBookingType.bind(this);
	}

	componentDidMount(props) {

		this.fetchGETVars(this.props.location.hash);
		this.props.location.hash = "";
	}

	fetchGETVars(BookingType) {

		let TypeExists = false;
		const BookingTypeFromURL = BookingType.replace("#", "");

		this.BookingTypes.forEach(MainBookingType => {

			const CurrentType = MainBookingType.Type;

			if (CurrentType == BookingTypeFromURL) {

				TypeExists = true;
			}
		});

		if (TypeExists == true) {

			this.setState({ LoadPaymentModal: true });
			this.simulateSelectBookingType(BookingTypeFromURL);
		}
	}

	simulateSelectBookingType(BookingType) {

		document.getElementById(BookingType).click();
	}

	selectBookingType(e) {

		var SelectedType = e.target;
		var SelectedTypeEntryId = SelectedType.getAttribute("data");
		var SelectedTypeType = SelectedType.getAttribute("custom-data");

		var AllBookingTypeButtons = document.getElementsByClassName("booking-type");

		Object.keys(AllBookingTypeButtons).forEach(Key => {

    		AllBookingTypeButtons[Key].classList.remove("button-primary");
    	});

		SelectedType.classList.add("button-primary");
		this.setState({ SelectedEntryId: SelectedTypeEntryId, Type: SelectedTypeType, IsReady: true });
	}

	render() {
		
		var self = this;

		return (
			<CustomElement Type="div">
				<LayoutRowDiv>
	    			<LayoutColumnDiv Columns="twelve">
	    				<CustomElement Type="h1" Class="margin-top-25 center-align">Training</CustomElement>
	    			</LayoutColumnDiv>
	    		</LayoutRowDiv>

	    		<LayoutRowDiv Id="BookingTypeButtons" Class="margin-top-25">
	    			<LayoutColumnDiv Columns="twelve" Class="margin-top-25 center-align">
	    				{this.BookingTypes.map(function(BookingType, index) {
							return (
								<CustomElement Type="button" Data={BookingType.EntryId} Id={BookingType.Type} CustomButtonData={BookingType.Type} Key={index} ButtonValue={index} ClickEvent={self.selectBookingType} Class="padding-right booking-type">
									{BookingType.Title}
								</CustomElement>
							)
						})}
	    			</LayoutColumnDiv>
	    		</LayoutRowDiv>

	    		<BookingType EntryId={this.state.SelectedEntryId} Type={this.state.Type} LoadPaymentModal={this.state.LoadPaymentModal} Class={this.state.IsReady === false ? "hide" : "show"} />
    		</CustomElement>
		);
	}
}
 
export default Bookings;