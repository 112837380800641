import React, { Component } from "react";
import { Route, HashRouter } from "react-router-dom";

import { NavLinkDetails } from "../framework/NavLinkDetails";
import CustomElement from "../framework/CustomElement";
 
class NavRoutes extends Component {
	render() {

		return (
			<HashRouter>
				<CustomElement Type="div" Class="content">
					{NavLinkDetails.map(function(Link, index) {
						return <Route exact path={Link.LinkPath} component={Link.Component} key={index} />
					})}
				</CustomElement>
			</HashRouter>
		);
	}
}
 
export default NavRoutes;