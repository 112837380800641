import React, { Component } from "react";

import CustomElement from "../framework/CustomElement";
 
class FormElement extends Component {

	constructor(props) {

		super(props);
		this.state = ({ FormElementName: "", DisplayName: "", ElementType: "input", Ref: "" });
	}

	componentWillReceiveProps(NewProps) {

		const ElementName = NewProps.ElementName;
		const DisplayName = NewProps.DisplayName;
		const ElementType = NewProps.ElementType;
		const Ref = NewProps.Ref;

		this.setState({ FormElementName: ElementName, DisplayName: DisplayName, ElementType: ElementType, Ref: Ref });
	}

	render() {

		return (
			<CustomElement Type="div" Id={this.state.FormElementName + "_Container"} Class={this.state.FormElementName !== "" ? "show" : "hide"}>
				
				<CustomElement Type="label" LabelFor={this.state.FormElementName}>{this.state.DisplayName}</CustomElement>
				<CustomElement Type={this.state.ElementType} InputType="text" Id={this.state.FormElementName} Class="ui-full-width input-full-column-width" Ref={this.state.Ref} />
			</CustomElement>
		);
	}
}
 
export default FormElement;