import React, { Component } from "react";

import { Includes } from "../framework/Includes";
import CustomElement from "../framework/CustomElement";
import LayoutRowDiv from "../framework/LayoutRowDiv";
import LayoutColumnDiv from "../framework/LayoutColumnDiv";
 
class YoutubeSubscribe extends Component {

	render() {
		return (
			<LayoutRowDiv Class="margin-top-25 center-align">
    			<LayoutColumnDiv Columns="twelve" Class="margin-top-25">
    				<CustomElement Type="YTSub" Class="g-ytsubscribe" Id="YoutubeSubscribeElement" ChannelID={Includes.YoutubeChannelID} DataLayout="full" DataCount="default" />
    			</LayoutColumnDiv>
    		</LayoutRowDiv>
		);
	}
}
 
export default YoutubeSubscribe;