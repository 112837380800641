import React, { Component } from "react";
import CustomElement from "../framework/CustomElement";
 
class LocationMap extends Component {
  render() {

    var MapsSource = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2315.8811379980866!2d-6.067972284131091!3d54.51795759400804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486104722a9b9e21%3A0xc64d24001d078560!2sPaul+Cutler+Taekwon-Do+Academy!5e0!3m2!1sen!2suk!4v1547566503641";

    return (
        <CustomElement Type="iframe" Class="location-iframe" Source={MapsSource} IFrameBorder="0" Id="MapsFeed" />
    );
  }
}
 
export default LocationMap;