import React, { Component } from "react";

import { Includes } from "../framework/Includes";
import CustomElement from "../framework/CustomElement";
import LayoutRowDiv from "../framework/LayoutRowDiv";
import LayoutColumnDiv from "../framework/LayoutColumnDiv";
import NavLinks from "../framework/NavLinks";
 
class Footer extends Component {

	render() {

		return (
			<CustomElement Type="div" Id="FooterContainer">
				<LayoutRowDiv Class="overflow-none">
					<LayoutColumnDiv Columns="twelve" Class="margin-top-25 center-align">
						<hr />
						<NavLinks />
						<CustomElement Type="span" Class="copyright-footer-text">{Includes.FooterCopyrightText}</CustomElement>
					</LayoutColumnDiv>
				</LayoutRowDiv>

				<LayoutRowDiv Class="margin-top-25" />
			</CustomElement>
		);
	}
}
 
export default Footer;