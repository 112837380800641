import React from "react";
import ReactDOM from "react-dom";
import Main from "./components/Main";

import "./css/index.css";
import "./css/normalize.css";
import "./css/skeleton.css";
import "./css/skeleton-custom.css";
import "./css/modal.css";
import "./js/modal.js";
import "./css/balloon-tooltip.css";

ReactDOM.render(
	
	<Main/>,
	document.getElementById("root")
);