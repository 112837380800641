import React, { Component } from 'react';

import CustomElement from './CustomElement.js';

class FontAwesomeLink extends Component {
	
	constructor() {
		
		super();

		this.state = {};
	}

	FontAwesomeClick(e) {

		const Link = e.getAttribute("data");
		window.open(Link, '_blank');
	}

	render() {

		const FAType = (this.props.FAType === undefined) ? "" : this.props.FAType;
		const SocialClass = (FAType === undefined) ? "" : FAType;
		const Class = (this.props.Class === undefined) ? "" : this.props.Class + " " + SocialClass;
		const Id = (this.props.Id === undefined) ? "" : this.props.Id;
		const Data = (this.props.Data === undefined) ? "" : this.props.Data;
		
	    return (

	        <CustomElement Type="i" Class={Class} Id={Id} Data={Data} ClickEvent={(e) => this.FontAwesomeClick(e.target)} />
	    );
  	}
}

export default FontAwesomeLink;