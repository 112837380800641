import React, { Component } from "react";

import { Includes } from "../framework/Includes";
import FontAwesomeLink from "../framework/FontAwesomeLink";
import CustomElement from "../framework/CustomElement";
import LayoutRowDiv from "../framework/LayoutRowDiv";
import LayoutColumnDiv from "../framework/LayoutColumnDiv";
 
class Quote extends Component {
	render() {

		return (
			
			<LayoutRowDiv>
				<LayoutColumnDiv Columns="twelve" Class="center-align homepage-quote">
					<CustomElement Type="h5" Class="quote-text">
						<FontAwesomeLink Class="fas fa-quote-left" FAType="quote-mark" />
						{Includes.MainPageQuote}
						<FontAwesomeLink Class="fas fa-quote-right" FAType="quote-mark" />
					</CustomElement>
				</LayoutColumnDiv>
			</LayoutRowDiv>
		);
	}
}
 
export default Quote;